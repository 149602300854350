import {
  Link,
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate,
} from "@remix-run/react";
import React, { useRef, useState } from "react";
import classNames from "classnames";
import { useRootLoaderData } from "~/root";
import {
  ChevronDownIcon,
  CircleStackIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDown,
  ChevronFirst,
  Menu,
  MenuIcon,
  UserIcon,
} from "lucide-react";
import { useLocalStorageValue } from "@react-hookz/web";
import { PageLoadingIndicator } from "./PageLoadingIndicator";
import { FeedbackModal } from "@konbert/ui/components/FeedbackButton";

const FilePlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v4" />
    <path d="M14 2v4a2 2 0 0 0 2 2h4" />
    <path d="M3 15h6" />
    <path d="M6 12v6" />
  </svg>
);

const FileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
    <path d="M14 2v4a2 2 0 0 0 2 2h4" />
    <path d="M8 13h2" />
    <path d="M14 13h2" />
    <path d="M8 17h2" />
    <path d="M14 17h2" />
  </svg>
);

export function Sidebar(): React.ReactNode {
  const { value: isCollapsed, set: setIsCollapsed } = useLocalStorageValue(
    "sidebarCollapsed",
    {
      defaultValue: false,
      initializeWithValue: false,
    }
  );

  const { dataSources, team } = useLoaderData<{
    team: { team: { id: string } } | null;
    dataSources:
      | {
          id: string;
          name: string;
        }[]
      | null;
  }>();
  const [expandedItems, setExpandedItems] = React.useState<
    Record<string, boolean>
  >({});
  const location = useLocation();

  if (team === null) {
    return null;
  }

  const teamId = team.team.id;
  type SubItem =
    | {
        type: "item";
        id?: string;
        title: string;
        leftIcon?: React.ElementType;
        rightIcon?: React.ElementType;
        href: string;
        className?: string;
      }
    | { type: "separator" };

  const sidebarItems: {
    title: string;
    icon: React.ElementType;
    isActive: () => boolean;
    subitems?: SubItem[];
  }[] = [
    {
      title: "Data sources",
      icon: CircleStackIcon,
      isActive: () => {
        return location.pathname.startsWith("/files");
      },
      subitems: [
        ...(dataSources && dataSources.length > 4
          ? [
              {
                type: "item",
                leftIcon: ListBulletIcon,
                title: "See all",
                href: `/app/${teamId}/sources`,
              } satisfies SubItem,
            ]
          : []),
        {
          type: "item",
          title: "New",
          leftIcon: FilePlusIcon,
          href: `/app/${teamId}/sources`,
        },
        { type: "separator" },
        ...(dataSources
          ? dataSources.slice(0, 4).map(
              (dataSource) =>
                ({
                  type: "item",
                  id: dataSource.id,
                  title: dataSource.name,
                  href: `/files/${dataSource.id}`,
                  leftIcon: FileIcon,
                } satisfies SubItem)
            )
          : []),
      ],
    },
  ];

  return (
    <div
      data-role="side-menu"
      className={classNames("bg-white shrink grow-0 border-r transition-all", {
        "min-w-72 max-w-72": !isCollapsed,
        "min-w-0 w-max": isCollapsed,
      })}
    >
      <header className="pb-10 pt-2 flex justify-start">
        <div className="p-2 flex w-full items-center">
          {!isCollapsed && (
            <img className="h-6 ml-2" src="/logo.svg" alt="TablePad" />
          )}
          <button
            className="hover:bg-gray-50 rounded-lg p-2 ml-auto text-gray-400"
            onClick={() => setIsCollapsed((prev) => !prev)}
          >
            <ChevronFirst
              className={classNames("w-6 h-6", {
                "rotate-180": isCollapsed,
              })}
            />
          </button>
        </div>
      </header>
      <nav className="flex flex-col gap-y-2 w-full p-2">
        {sidebarItems.map(({ isActive, title, icon: Icon, subitems }) => {
          const isParentActive = isActive();
          const isExpanded = expandedItems[title] ?? isParentActive;

          return (
            <div key={title} className="w-full">
              <button
                onClick={() => {
                  if (isCollapsed) {
                    setIsCollapsed(false);
                    setExpandedItems((prev) => ({ ...prev, [title]: true }));
                  } else {
                    if (isExpanded) {
                      setExpandedItems((prev) => ({ ...prev, [title]: false }));
                      return;
                    }

                    setExpandedItems((prev) => ({ ...prev, [title]: true }));
                  }
                }}
                className={classNames(
                  "w-full cursor-pointer py-2 px-3 rounded-lg flex gap-1 items-center font-medium text-gray-700",
                  {
                    "hover:bg-gray-50": !isExpanded,
                    "text-gray-900 bg-gray-100": isParentActive,
                  }
                )}
              >
                <Icon
                  strokeWidth={1.5}
                  className={classNames(
                    "w-5 h-5 text-gray-500 shrink-0 ml-[-3px]",
                    {
                      "text-gray-900": isParentActive,
                    }
                  )}
                />
                {!isCollapsed && (
                  <span className="ml-2 truncate leading-5">{title}</span>
                )}
                {!isCollapsed && subitems && subitems.length > 0 && (
                  <ChevronDownIcon
                    className={classNames(
                      "w-5 h-5 text-gray-500 transition-transform ml-auto",
                      {
                        "transform rotate-180": isExpanded,
                      }
                    )}
                  />
                )}
              </button>
              {!isCollapsed && subitems && isExpanded && (
                <div className="flex flex-col gap-0.5 pt-2 pl-2">
                  {subitems.map((subItem, i) => {
                    if ("type" in subItem && subItem.type === "separator") {
                      return (
                        <div
                          key={i}
                          className="border-t border-gray-100 my-1"
                        ></div>
                      );
                    }

                    const {
                      id,
                      title,
                      leftIcon: LeftIcon,
                      rightIcon: RightIcon,
                      className,
                      href,
                    } = subItem;

                    return (
                      <NavLink
                        to={href}
                        key={id ?? title}
                        className={({ isActive }) =>
                          classNames(
                            "w-full cursor-pointer py-1.5 px-3 rounded-lg flex gap-1 items-center text-gray-700 hover:bg-gray-50 min-w-0",
                            className,
                            {
                              "text-gray-900 bg-gray-100": isActive,
                            }
                          )
                        }
                      >
                        {LeftIcon && (
                          <LeftIcon
                            strokeWidth={1.5}
                            className="w-4 h-4 text-gray-400 mr-1.5 shrink-0"
                          />
                        )}
                        <span className="truncate">{title}</span>
                        {RightIcon && (
                          <RightIcon
                            strokeWidth={1.5}
                            className="w-[1em]  text-gray-500 ml-auto"
                          />
                        )}
                      </NavLink>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </nav>
    </div>
  );
}

const MenuItem = ({
  to,
  className,
  children,
}: {
  to: string;
  className?: string;
  children: React.ReactNode;
}) => (
  <li>
    <Link
      to={to}
      className={`px-1 py-3 md:py-2 md:px-3 flex items-center font-medium leading-snug rounded-lg hover:bg-gray-100 text-sm ${
        className || ""
      }`}
    >
      {children}
    </Link>
  </li>
);

const Header = () => {
  const { user } = useRootLoaderData();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isUserOptionsVisible, setIsUserOptionsVisible] = useState(false);
  const hideTimeoutRef = useRef<number | null>(null);

  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);

  const showUserOptions = () => {
    if (hideTimeoutRef.current !== null) {
      clearTimeout(hideTimeoutRef.current);
    }
    setIsUserOptionsVisible(true);
  };

  const hideUserOptions = () => {
    hideTimeoutRef.current = window.setTimeout(() => {
      setIsUserOptionsVisible(false);
    }, 200);
  };

  return (
    <header className="w-full z-20 px-4 md:px-8">
      <FeedbackModal
        userEmail={user?.email ?? undefined}
        show={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
      <div className="mx-auto">
        <div className="py-4 md:py-4 mx-auto w-full">
          <nav className="flex flex-wrap items-center justify-between">
            <div className="relative w-full flex flex-wrap items-center justify-between">
              <div className="w-full flex items-center justify-between md:w-auto md:static md:block md:justify-start">
                <div className="flex items-center w-full">
                  <Link to="/" className="block w-auto h-6">
                    <img
                      className="h-full w-auto"
                      width="470"
                      height="95"
                      src="/images/konbert-logo.svg"
                      alt=""
                    />
                  </Link>
                  <div id="header-detail"></div>
                </div>
                <button
                  onClick={toggleNavbar}
                  className="ml-auto cursor-pointer text-2xl leading-none py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
                  type="button"
                >
                  <MenuIcon size={24} />
                </button>
              </div>
              <div
                className={`md:flex md:flex-grow items-center ${
                  isNavbarOpen ? "" : "hidden"
                } pt-6 md:pt-0`}
              >
                <ul className="flex flex-col md:flex-row list-none md:ml-auto leading-7 md:gap-x-2">
                  <MenuItem to="/generator">Generator</MenuItem>
                  <MenuItem to="/viewer">Viewer</MenuItem>
                  <MenuItem to="/convert">Convert</MenuItem>
                  <MenuItem to="/desktop">
                    <span>Desktop</span>
                  </MenuItem>
                  <MenuItem to="/docs">API</MenuItem>
                  <MenuItem to="/blog">Blog</MenuItem>
                  <MenuItem to="https://konbert.featurebase.app/changelog">
                    Changelog
                  </MenuItem>
                  <li className="border-r border-gray-200 my-2"></li>
                  {user ? (
                    <li
                      className="relative flex items-center"
                      onMouseEnter={showUserOptions}
                      onMouseLeave={hideUserOptions}
                    >
                      <MenuItem to="/account">Account</MenuItem>
                      {isUserOptionsVisible && (
                        <div className="md:absolute right-0 top-[100%] z-10 p-1">
                          <div className="bg-white border rounded-md shadow-sm focus:outline-none w-max">
                            <div className="divide-y divide-gray-100 text-sm">
                              <div className="px-3 py-3 pr-8">
                                <div className="font-medium">{user.name}</div>
                                <div className="text-gray-500">
                                  {user.email}
                                </div>
                              </div>
                              <div className="pt-3">
                                <Link
                                  to="/account"
                                  className="px-3 pb-3 block leading-snug"
                                >
                                  Settings
                                </Link>
                                <Link
                                  to="/pricing"
                                  className="px-3 pb-3 block leading-snug"
                                >
                                  Change plan
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to="/logout"
                                  reloadDocument
                                  className="px-3 py-3 block leading-snug w-full text-left"
                                >
                                  Log out
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ) : (
                    <MenuItem to="/login">Sign in</MenuItem>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default function Layout(props: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <PageLoadingIndicator />
      <main className={"min-h-screen w-full " + props.className}>
        <Header />
        {props.children}
      </main>
      <footer className="bg-primary-800 text-primary-100screen-hide">
        <div className="max-w-screen-lg w-full px-4 mx-auto py-12 text-primary-100">
          <div className="flex gap-4 pb-8 flex-col md:flex-row [&>a:hover]:text-white [&>a:hover]:underline text-primary-100">
            <Link to="/pricing">Pricing</Link>
            <Link to="/privacy-policy">Privacy policy</Link>
            <Link to="/terms-and-conditions">Terms and conditions</Link>
            <Link to="https://konbert.featurebase.app/" rel="nofollow">
              Report an issue
            </Link>
            <Link to="https://konbert.featurebase.app/" rel="nofollow">
              Request a feature
            </Link>
          </div>
          <p className="">
            &copy; {new Date().getFullYear()} Konbert. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
